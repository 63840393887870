<template>
  <section id="bienvenido" class="fondo-transparente">
    <v-container>
      <v-row>
        <v-col cols="12" lg="8" md="12">
          <h1
            class="text-uppercase"
            v-for="(item, i) in items"
            :key="i"
            text
          >
            {{ item.text }}
          </h1>
          <p
            class="text-justify parrafo-bienvenido mt-8"
            v-for="(item, i) in items2"
            :key="'A' + i"
            text
          >
            {{ item.text }}
          </p>
          <v-btn
            class="mt-2 mb-15 top float-right btn-leer-mas"
            color="#003791"
            elevation="3"
            large
            :to="'/historia'"
            outlined
          >
            <v-icon left>mdi-arrow-right</v-icon> <!-- Icono a la izquierda -->
            Leer más
          </v-btn>
        </v-col>
        <v-col cols="12" lg="4" md="12" class="d-flex justify-center align-center">
          <v-img
            dark
            height="250"
            width="250"
            src="@/assets/img/logoNiCircle.png"
            contain
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [{ text: "Bienvenidos a Nic.Ni" }],
    items2: [
      {
        text:
          "Somos una organización sin fines de lucro, dedicada a promover el uso de internet en nuestro país, teniendo como meta alcanzar a los sectores de la sociedad nicaragüense con pocos recursos económicos, facilitándoles el registro de su dominio, el hospedaje de sus sitios web y buzones de correo.",
      },
    ],
  }),
};
</script>

<style scoped>
/* Fondo azul transparente para la sección */
.fondo-transparente {
  background-color: rgba(0, 55, 145, 0.2); /* Azul con opacidad */
  padding: 20px 0; /* Espaciado interno */
}

/* Ajuste de color del párrafo */
.parrafo-bienvenido {
  color: #000 !important;
  font-size: 18px;
  line-height: 1.6;
}

/* Estilo para el título con línea */
.linebt {
  position: relative;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
}

.linebt::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #398bf7; /* Color azul */
}

/* Estilo del botón de "Leer más" */
.btn-leer-mas {
  transition: background-color 0.3s ease;
  background-color: #003791;
}

.btn-leer-mas:hover {
  background-color: rgba(57, 139, 247, 0.7); /* Color con traslucidez */
  color: #11a1d0; /* Cambiar color del texto */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Aumenta la sombra */
}

/* Estilo para la imagen */
.v-img {
  border-radius: 50%;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 725px) {
  .linebt {
    font-size: 28px !important;
  }

  .parrafo-bienvenido {
    font-size: 16px;
  }

  /* Ocultar imagen en pantallas pequeñas */
  .v-img {
    display: none !important;
  }

  .btn-leer-mas {
    width: 100%; /* Hacer el botón más grande en pantallas pequeñas */
  }
}
</style>
